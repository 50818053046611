<template>
  <div class="crm-navbar shadow h-100 bg-white">
    <div class="crm-navbar-inner h-100 py-3 px-2 d-flex flex-column">
      <div class="px-3 mb-2">
        <img
          class="logo"
          :src="require(`assets/images/${logo}.svg?inline`)"
        >
        <p class="my-2 text-right font-weight-bold">
          {{ phone }}
        </p>
      </div>
      <b-nav
        vertical
        class="crm-navbar-hero mb-auto overflow-auto flex-nowrap"
      >
        <template
          v-for="link in links"
        >
          <navbar-item
            v-if="!link.pageId"
            :key="link.id"
            :link="link"
          />
        </template>
      </b-nav>

      <div
        class="crm-navbar-footer border-top mt-3 mb-0 pt-3 px-3"
      >
        <div
          class="align-items-center d-flex text-secondary"
        >
          {{ loggedUserName }}

          <b-icon
            icon="box-arrow-right"
            class="ml-3 navbar-logout-icon"
            title="Выйти"
            @click="logout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { DOMAIN_SETTINGS } from '@/helpers/consts';

import NavbarItem from '@/components/NavbarItem';
import { APP_SETTINGS } from '@/app.config';
import HubClientService from '@/services/core/hub';

export default {
  name: 'Navbar',
  components: {
    NavbarItem,
  },
  mixins: [mixinRoles],
  data() {
    return {};
  },
  computed: {
    domain() {
      return DOMAIN_SETTINGS[APP_SETTINGS || 'default'];
    },
    logo() {
      return this.domain.logo;
    },
    phone() {
      return this.domain.phone || '8 800 500 0299';
    },
    loggedUserName() {
      if (!this.user) return null;

      let name = this.user.lastName;
      if (this.user.firstName) name += ` ${this.user.firstName[0]}.`;
      if (this.user.middleName) name += ` ${this.user.middleName[0]}.`;
      return name;
    },
    user() {
      return this.$store.state.Auth.user;
    },
    links() {
      const links = this.user.roles[0].pages.filter((p) => p.isMenuItem);
      console.log('links: ', links);
      return links?.sort((a, b) => a.index - b.index).map((p) => this.formatPage(p)) || [];
    },
  },
  created() {
    this.setFavicon();
  },
  methods: {
    formatPage(page) {
      return {
        ...page,
        childPages: page?.childPages?.sort((a, b) => a.index - b.index).map((p) => this.formatPage(p)) || [],
      };
    },
    setFavicon() {
      const favicon = document.getElementById('favicon');
      const faviconUrl = `/${this.domain.favicon}.ico`;
      favicon.href = faviconUrl;
    },
    async logout() {
      await HubClientService.stop();
      this.$store.dispatch('Auth/userLogOut');

      if (localStorage.getItem('internal')) {
        window.location.reload();
      }
    },
  },
};
</script>

<!-- $scrol-collor-one: rgb(69, 69, 69); -->
<!-- $scrol-collor-two: rgb(224, 224, 224); -->
<style lang="scss" scoped>
$scrol-collor-one: #c9c9c9;
$scrol-collor-two: #fff;

.crm-navbar-hero {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: $scrol-collor-one $scrol-collor-two;
}
/* Works on Chrome, Edge, and Safari */
.crm-navbar-hero::-webkit-scrollbar {
  width: 6px;
}
.crm-navbar-hero::-webkit-scrollbar-track {
  background: $scrol-collor-two;
  border-radius: 20px;
}
.crm-navbar-hero::-webkit-scrollbar-thumb {
  visibility: hidden;
  background-color: $scrol-collor-one;
  border-radius: 20px;
  border: 2px solid $scrol-collor-two;
  transition: .3s;
}

.crm-navbar-hero:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

::v-deep.crm-navbar {
  width: 250px;
  flex-shrink: 0;
  transition: all 1s;
  position: relative;
  z-index: 10;

  &-inner {
    width: 250px;
    transition: all 1s;
  }

  &__logo-wrapper {
    position: relative;
  }

  .nav-link {
    color: inherit;

    &:hover {
      opacity: 0.7;
    }
  }

  .logo {
    max-width: 100%;
    max-height: 50px;
  }
}

.navbar-logout-icon {
  font-size: 1.4rem;
  cursor: pointer;
}
</style>
