<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Шаблон сообщения"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        invalid-feedback="Поле является обязательным"
        :state="!$v.text.$error"
      >
        <label class="col-form-label required">
          Текст шаблона
        </label>

        <b-form-textarea
          v-model="$v.text.$model"
          placeholder="Введите текст"
          rows="4"
          required
        />
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showValidationErrorMessage } from '@/helpers/messages';
import Preloader from '@/components/Preloader';

export default {
  name: 'SmsTemplateEditModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    template: {
      type: Object,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterSaveFunc: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isSaving: false,
      text: '',
    };
  },
  validations() {
    return {
      text: { required },
    };
  },
  mounted() {
    if (this.template?.id) this.text = this.template.text;
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        const methodName = this.template?.id ? 'SMS_TEMPLATE_UPDATE' : 'SMS_TEMPLATE_CREATE';
        const templateData = { text: this.text };
        if (this.template?.id) templateData.id = this.template.id;

        await this.$store.dispatch(this.$types[methodName], templateData);
        this.afterSaveFunc();

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      } catch (error) {
        console.error(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
