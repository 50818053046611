<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="pb-5">
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Номер консультации
        </div>
        <base-async-select
          v-model="$v.consultation.$model"
          :error="$v.consultation.$error"
          :fetch-function="fetchConsultations"
          loading-title="Загрузка консультаций"
          no-options-title="Консультации не найдены"
          server-paginated
          fluid
        />
        <div
          v-if="$v.consultation.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.consultation[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Целевой врач:
        </div>
        <v-select
          v-model="$v.selectedSpecialization.$model"
          :options="specializations"
          placeholder="Выберите специализацию"
          fluid
          label="name"
        />

        <div
          v-if="$v.selectedSpecialization.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.selectedSpecialization[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          ФИО врача:
        </div>
        <base-async-select
          :key="selectedSpecialization ? selectedSpecialization.id : null"
          v-model="$v.selectedDoctor.$model"
          :fetch-function="fetchDoctors"
          placeholder="Выберите врача"
          loading-title="Загрузка докторов"
          no-options-title="Доктора не найдены"
          server-paginated
          fluid
        />
        <div
          v-if="$v.selectedDoctor.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.selectedDoctor[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Инициатор
        </div>
        <b-select
          v-model="$v.selectedInitiatorId.$model"
          value-field="id"
          text-field="name"
          :options="initiators"
        />
        <div
          v-if="$v.selectedInitiatorId.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.selectedInitiatorId[0] }}
        </div>
      </div>
      <div class="mb-3">
        <div class="small font-weight-bold mb-1">
          Причина переноса консультации
        </div>
        <v-select
          v-model="$v.selectedTransferReason.$model"
          label="reason"
          :options="reasons"
          :clearable="true"
          class="crm-select"
          placeholder="Выберите причину"
          :disabled="disabledTransferReason"
          loading-title="Загрузка"
        />

        <div
          v-if="$v.selectedTransferReason.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.selectedTransferReason[0] }}
        </div>
      </div>

      <div v-if="selectedDoctor">
        <div class="schedule">
          <b-skeleton-img
            v-if="slotsLoading"
            no-aspect
            width="100%"
            height="84px"
            class="rounded"
          />
          <schedule-wrapper
            v-if="allSlots.length"
            :key="selectedDoctor.id"
            :doctor-id="selectedDoctor.id"
            :all-slots="allSlots"
          >
            <schedule @change-active-tab="activeTimeTab = $event" />
          </schedule-wrapper>

          <div v-if="!allSlots.length && !slotsLoading">
            У врача нет ни одного слота для записи. Проверьте шаблоны расписания врача
          </div>

          <div
            v-if="$v.activeTimeTab.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.activeTimeTab[0] }}
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        @click="onClose"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="ml-2"
        @click="onClickSave"
      >
        Перенести
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import Bus from '@/eventBus';

import { showValidationErrorMessage } from '@/helpers/messages';
import { BaseAsyncSelect } from '@/components/base';
import ScheduleWrapper from '@/components/Schedule/Modals/ScheduleWrapper';
import Schedule from '@/components/Schedule/Modals/Schedule';

export default {
  name: 'ConsultationTransferModal',
  components: {
    BaseAsyncSelect,
    ScheduleWrapper,
    Schedule,
  },
  mixins: [validationMixin],
  props: {
    consultationProp: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Перенос консультации',
    },
  },
  data() {
    return {
      isOpen: true,
      consultation: null,
      selectedDoctor: null,
      selectedTransferReason: null,
      selectedSpecialization: null,
      activeTimeTab: null,
      specializations: [],
      reasons: [],
      selectedInitiatorId: null,
      initiators: [
        {
          id: 0,
          name: 'Врач',
        },
        {
          id: 1,
          name: 'Пациент',
        },
        {
          id: 2,
          name: 'Техническая ошибка',
        },
      ],
      allSlots: [],
      slotsLoading: false,
      isSaving: false,
    };
  },
  validations() {
    return {
      consultation: {
        required,
      },
      selectedSpecialization: {},
      selectedDoctor: {
        required,
      },
      selectedInitiatorId: {
        required,
      },
      selectedTransferReason: {
        required,
      },
      activeTimeTab: {
        // required,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.consultation = [];
      if (!this.$v.consultation.required) {
        errors.consultation.push('Поле не может быть пустым');
      }

      errors.selectedDoctor = [];
      if (!this.$v.selectedDoctor.required) {
        errors.selectedDoctor.push('Поле не может быть пустым');
      }

      errors.selectedTransferReason = [];
      if (!this.$v.selectedTransferReason.required) {
        errors.selectedTransferReason.push('Поле не может быть пустым');
      }

      errors.selectedInitiatorId = [];
      if (!this.$v.selectedInitiatorId.required) {
        errors.selectedInitiatorId.push('Поле не может быть пустым');
      }

      errors.activeTimeTab = [];
      if (!this.$v.activeTimeTab.required) {
        errors.activeTimeTab.push('Необходимо выбрать слот');
      }

      return errors;
    },
    disabledTransferReason() {
      return this.selectedInitiatorId === null || this.selectedDoctor === null || this.selectedSpecialization === null;
    },
  },
  watch: {
    selectedSpecialization() {
      this.selectedDoctor = null;
      this.selectedTransferReason = null;
    },
    selectedDoctor() {
      this.activeTimeTab = null;
      this.selectedTransferReason = null;
    },
    'selectedDoctor.id': {
      async handler(id) {
        await this.fetchAllSlots(id);
      },
    },
    async selectedInitiatorId() {
      await this.fetchTransferReasons();
      this.selectedTransferReason = null;
    },
  },
  async created() {
    this.consultation = this.formatConsultation(this.consultationProp);
    this.specializations = await this.fetchSpecializations('');
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchAllSlots(id) {
      this.slotsLoading = true;

      try {
        this.allSlots = await this.$store.dispatch(this.$types.FETCH_DOCTOR_ALL_SLOTS, id);
      } catch (e) {
        console.error(e);
      } finally {
        this.slotsLoading = false;
      }
    },
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        doctorSpecializationId: this.selectedSpecialization.id,
        save: false,
      });

      return doctors.map((doctor) => ({ ...doctor, value: doctor.id, name: doctor.fullName }));
    },
    async fetchTransferReasons() {
      this.reasons = await this.$store.dispatch(this.$types.TRANSFER_REASONS_FETCH, this.selectedInitiatorId);
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    async fetchConsultations({ query, skip, take }) {
      const consultations = await this.$store.dispatch(this.$types.CONSULTATIONS_FETCH, {
        skip,
        take,
        query,
      });

      return consultations.map((consultation) => this.formatConsultation(consultation));
    },
    async onClickSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isSaving = true;

        await this.$store.dispatch(this.$types.CONSULTATIONS_TRANSFER, {
          consultationId: this.consultation.value,
          targetDoctorSpecializationId: this.selectedDoctor.mainDoctorSpecialization.id,
          transferReason: this.selectedTransferReason.id,
          unixTime: this.activeTimeTab,
        });

        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_RESET);
        Bus.$emit('consultations:update', this.consultation.value);
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    formatConsultation(consultation) {
      const { lastName, firstName, middleName } = consultation.patient;
      return {
        value: consultation.id,
        name: `${consultation.id} — ${lastName} ${firstName} ${middleName}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  width: 100%;
  position: relative;
}
</style>
