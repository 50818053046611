import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { createBlobFromArrayBuffer, removeNamespaces, showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.PATIENTS_FETCH]({ commit }, {
    skip,
    query,
    take,
    clear,
    save,
    filter,
  }) {
    NProgress.start();
    if (save && clear) {
      commit(localTypes.PATIENTS_SET, []);
    }

    try {
      const params = {};

      if (filter.phone && filter.phone.length) params.phone = filter.phone;
      if (filter.policyCode && filter.policyCode.length) params.policyId = filter.policyCode;
      if (filter.id) params.id = filter.id;
      if (filter.isRegistered) params.hasMobileAccount = filter.isRegistered;
      if (filter.subProgramId) params.subProgramId = filter.subProgramId;
      if (filter.policyId) params.policyId = filter.policyId;

      if (filter.policyStartDate) params.policyStartDate = filter.policyStartDate;
      if (filter.policyEndDate) params.policyEndDate = filter.policyEndDate;
      if (filter.hasConsultations) params.hasConsultations = filter.hasConsultations;
      if (filter.hasActivePolicy) params.hasActivePolicy = filter.hasActivePolicy;
      if (filter.hasMobileAccount) params.hasMobileAccount = filter.hasMobileAccount;

      if (filter.birthDate && filter.birthDate.length) params.birthDate = filter.birthDate;
      if (!filter.isActive && query && query.length) params.searchString = query;
      if (filter.isActive && filter.lastName && filter.lastName.length) params.lastName = filter.lastName;
      if (filter.isActive && filter.firstName && filter.firstName.length) params.firstName = filter.firstName;
      if (filter.isActive && filter.middleName && filter.middleName.length) params.middleName = filter.middleName;

      params.skip = skip;
      params.take = take;

      const { data: { result: patients } } = await api.post('v2/person/find', params);

      if (save) {
        commit(localTypes.PATIENTS_ADD, patients);
      }
      return patients;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.FAMILY_PATIENTS_FETCH]({ commit }, {
    skip,
    save,
    clear,
    policyCode,
  }) {
    NProgress.start();
    if (save && clear) {
      commit(localTypes.PATIENTS_SET, []);
    }

    try {
      const params = {
        skip,
      };

      const { data } = await api.post('/v2/person/find-family', params, {
        params: {
          policyId: policyCode,
        },
      });

      const patients = data.map(({ person, policy, childPersons }) => ({
        person,
        policies: [policy],
        // eslint-disable-next-line max-len
        childrens: childPersons && childPersons.length !== 0 ? childPersons.map((childItem) => ({ person: childItem, policies: [policy] })) : [],
      }));

      if (save) {
        commit(localTypes.PATIENTS_ADD, patients);
      }

      return patients;
    } catch (e) {
      console.error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENTS_DELETED_FETCH]({ commit }, {
    skip,
    query,
    take,
    clear,
    save,
    filter,
  }) {
    NProgress.start();
    if (save && clear) {
      commit(localTypes.PATIENTS_DELETED_SET, []);
    }

    try {
      const params = {};

      if (filter.phone && filter.phone.length) params.phone = filter.phone;
      if (filter.policyCode && filter.policyCode.length) params.policyId = filter.policyCode;
      if (filter.id) params.id = filter.id;
      if (filter.isRegistered) params.hasMobileAccount = filter.isRegistered;
      if (filter.subProgramId) params.subProgramId = filter.subProgramId;
      if (filter.birthDate && filter.birthDate.length) params.birthDate = filter.birthDate;
      if (!filter.isActive && query && query.length) params.searchString = query;
      if (filter.isActive && filter.lastName && filter.lastName.length) params.lastName = filter.lastName;
      if (filter.isActive && filter.firstName && filter.firstName.length) params.firstName = filter.firstName;
      if (filter.isActive && filter.middleName && filter.middleName.length) params.middleName = filter.middleName;

      params.skip = skip;
      params.take = take;

      const { data: { result: patients } } = await api.post('v2/person/findDeleted', params);

      if (save) {
        commit(localTypes.PATIENTS_DELETED_ADD, patients);
      }

      return patients;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.INSURANCE_PROGRAMMS_FETCH]() {
    try {
      const { data } = await api.get('InsurancePrograms');

      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.INSURANCE_COMPANIES_FETCH]() {
    try {
      const { data } = await api.get('v2/insuranceCompanies', {
        params: {
          getHidden: false,
        },
      });

      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.INSURANCE_SUBPROGRAMS_FETCH]() {
    try {
      const { data } = await api.get('v2/insuranceCompanies/programs/subprograms');

      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.PATIENT_SERVICES_FETCH]({ commit }, {
    policyId,
    startDate,
    endDate,
    legalPersonId,
    subprogramId,
  }) {
    let formateId = '';
    if (legalPersonId !== '') {
      formateId = legalPersonId.id;
    }

    const params = new URLSearchParams();
    params.append('policyId', policyId);
    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    params.append('legalPersonId', formateId);
    if (subprogramId !== undefined && subprogramId !== null) params.append('subprogramId', subprogramId);

    try {
      const { data } = await api.get('/v2/policy/getPolicyServices', { params });
      commit(localTypes.PATIENT_SERVICES_SET, data);
      commit(localTypes.PATIENT_POLICY_ID_SET, policyId);

      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.DOWNLOAD_SUBPROGRAM_DOCUMENTS](_, {
    subProgramId,
    type,
  }) {
    NProgress.start();

    try {
      const params = { subProgramId, type };

      const { data } = await api.get('/v2/insuranceCompanies/programs/subprogram/download-file', {
        params,
        responseType: 'arraybuffer',
      });

      return createBlobFromArrayBuffer(data, 'application/pdf');
    } catch (e) {
      console.error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LEGAL_LIST_FETCH]() {
    try {
      const { data } = await api.get('/v2/clinic/listLegalPerson');
      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.POLICY_HISTORY_FETCH](_, param) {
    try {
      const { data } = await api.get(`/v2/policy/policyHistory?policyId=${param}`);
      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.PATIENT_HISTORY_FETCH](_, personId) {
    try {
      const { data } = await api.get(`v2/person/history?personId=${personId}`);
      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.PATIENT_CREATE](ctx, patient) {
    NProgress.start();

    try {
      const { data: { data: createdPatient, errors } } = await api.post('v2/person', patient);

      if (errors) throw errors[0].message;

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Пациент успешно добавлен!',
      });

      return createdPatient;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_ONE_TIME_CREATE](ctx, patient) {
    NProgress.start();

    try {
      const { data: { data: createdPatient, errors } } = await api.post('v2/person/one-time', patient);

      if (errors) throw errors[0].message;

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Пациент успешно добавлен!',
      });

      return createdPatient;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_CREATE_WITH_MODE](ctx, patient) {
    NProgress.start();

    try {
      const data = await api.post('v2/person/create-with-mode', patient);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Пациент успешно добавлен!',
      });
      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_UPDATE](ctx, patient) {
    NProgress.start();

    try {
      const { data: { data: updatedPatient } } = await api.put('v2/person', patient);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Пациент успешно обновлен!',
      });

      return updatedPatient;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_FETCH](ctx, id) {
    NProgress.start();

    try {
      const { data: { data: patient } } = await api.get(`v2/person/${id}`);
      return patient;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о пациенте',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_FROM_POLICY_FETCH](_, {
    subProgramId,
    policyId,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      params.append('policyId', policyId);
      params.append('subProgramId', subProgramId);

      const { data: patient } = await api.get('v2/person/searchFromPolicy', { params });

      if (!patient.id) {
        throw new Error('Не удалось получить информацию о пациенте');
        // showMessage({
        //   type: 'error',
        //   title: 'Ошибка',
        //   message: 'Не удалось получить информацию о пациенте',
        // });
      }

      return patient;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о пациенте',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_DELETE](ctx, id) {
    NProgress.start();

    try {
      await api.delete(`v2/person/${id}`);
      showMessage({
        type: 'success',
        title: 'Успех',
        message: 'Пациент успешно удален!',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить пациента',
      });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.PATIENT_ADD_DOP](ctx, { personId, mainPersonId }) {
    NProgress.start();

    try {
      const params = {
        personId,
        mainPersonId,
      };

      await api.put('v2/person/add-dop-sign', params);
      showMessage({
        type: 'success',
        title: 'Успех',
        message: 'Пользователь прикреплен к основному',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось прикрепить пользователя к основному',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_DELETE_DOP](ctx, { personId }) {
    NProgress.start();

    try {
      const params = {
        personId,
      };
      await api.put('v2/person/remove-dop-sign', params);
      showMessage({
        type: 'success',
        title: 'Успех',
        message: 'Учетная запись сделана основной',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось сделать учетную запись основной',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_DEPERSONALIZATE](ctx, id) {
    NProgress.start();

    try {
      await api.put(`v2/person/depersonalizate/${id}`);
      showMessage({
        type: 'success',
        title: 'Успех',
        message: 'Пациент успешно деперсонализирован!',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деперсонализировать пациента',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_REESTABLISH](ctx, id) {
    NProgress.start();

    try {
      await api.put(`v2/person/reestablish?personId=${id}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Пациент успешно восстановлен!',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось восстановить пациента',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_TRANSFER](ctx, { personIdTo, personIdFrom, comment }) {
    NProgress.start();

    try {
      const params = {
        personIdTo,
        personIdFrom,
        comment,
      };

      await api.put('v2/person/transfer', params);
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось объединить учетные записи',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.PATIENT_POLICY_GET]({ commit }, { policyId, subProgramId }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('id', policyId);
      if (subProgramId) params.append('subProgramId', subProgramId);

      const { data } = await api.get('Policy/Details', { params });

      commit(localTypes.PATIENT_POLICY_SET, data);
    } catch (e) {
      showMessage({ type: 'error', message: 'Не удалось получить полис пациента' });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_POLICY_CREATE](ctx, policy) {
    NProgress.start();

    try {
      await api.post('Policy/Create', policy);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Полис успешно добавлен!',
      });
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: e?.response?.data?.message,
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_POLICY_UPDATE](ctx, policy) {
    NProgress.start();

    try {
      await api.put('Policy', policy);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Полис успешно обновлен!',
      });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: e.response.data,
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_POLICY_DELETE](ctx, id) {
    NProgress.start();

    try {
      await api.delete(`v2/policy?id=${id}`);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить полис',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_POLICY_DETACH]({ commit }, id) {
    NProgress.start();

    try {
      const { data } = await api.post(`v2/policy/detach?policyId=${id}`);

      if (data) {
        commit(types.OPEN_MODAL, {
          name: 'PolicyHasIndemnityModal',
          props: {
            policyId: id,
            text: data,
          },
        }, { root: true });
      }
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось открепить полис',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_POLICY_DETACH_DOP]({ commit }, { policyId, personId }) {
    NProgress.start();

    try {
      const { data } = await api.post(`v2/policy/${policyId}/detach?personId=${personId}`);

      if (data) {
        commit(types.OPEN_MODAL, {
          name: 'PolicyHasIndemnityModal',
          props: {
            policyId,
            text: data,
          },
        }, { root: true });
      }
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось открепить полис',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_POLICY_ACTIVATE](ctx, policy) {
    NProgress.start();

    try {
      await api.put('Policy/Activate', policy);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Полис успешно обновлен!',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать полис',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_CONSULTATIONS_FETCH]({ commit }, {
    skip,
    take,
    patientId,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (skip) params.append('skip', skip);
      params.append('take', take);
      const { data: consultations } = await api.get(`v2/person/${patientId}/consultations`, {
        params,
      });

      commit(localTypes.PATIENT_CONSULTATIONS_ADD, consultations);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить консультации пациента',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_APPEALS_FETCH]({ commit }, {
    skip,
    take,
    patientId,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);
      params.append('patientId', patientId);
      const { data: appeals } = await api.get('v2/appeal', {
        params,
      });

      commit(localTypes.PATIENT_APPEALS_ADD, appeals);

      return appeals;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить обращения пациента',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_APPEALS_NEW_FETCH](_, personId) {
    NProgress.start();

    try {
      const params = {
        personId,
      };

      const { data } = await api.get('v3/appeal/get-from-person', {
        params,
      });

      return data;
    } catch (e) {
      console.error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_APPEALS_RESPONSIBLES_FETCH]({ commit }, {
    save,
  } = {}) {
    NProgress.start();

    try {
      const { data: responsibles } = await api.get('v2/appeal/responsibles');

      if (save) commit(localTypes.PATIENT_APPEALS_RESPONSIBLES_SET, responsibles);

      return responsibles;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список ответственных лиц',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_LIMITS_FETCH](ctx, {
    personId,
    policyId,
  } = {}) {
    NProgress.start();

    const params = new URLSearchParams();

    if (policyId) params.append('policyId', policyId);

    try {
      const { data: limits } = await api.get(`v2/person/${personId}/limits`, { params });

      return limits;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список лимитов (оказанных услуг)',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_LIMITS_BY_PERSON_ID_FETCH](ctx, {
    personId,
    policyId,
  } = {}) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (personId) params.append('personId', personId);
      if (policyId) params.append('policyId', policyId);

      const { data } = await api.get('v2/person/get-limits', { params });

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список лимитов',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_LIMITS_EXPORT](ctx, { personId }) {
    NProgress.start();

    try {
      const data = await api.post(`v2/person/${personId}/limits/export`, {}, { responseType: 'blob' });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_ALL_LIMITS_EXPORT](ctx, { personId, policyId }) {
    NProgress.start();

    try {
      const data = await api.post('v2/person/export-limits', {}, {
        responseType: 'blob',
        params: { personId, policyId },
      });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_LIMITS_SAVE](ctx, { personId, limits }) {
    NProgress.start();

    try {
      await api.put(`v2/person/${personId}/limits`, limits);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Лимиты успешно обновлены!',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить лимиты',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_AUTH_TOKEN_FETCH](ctx, personId) {
    NProgress.start();

    try {
      const { data: token } = await api.post(`v2/person/${personId}/doctis/auth`);

      return token;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PATIENT_PHONE_EXIST_CHECK](ctx, phoneNumber) {
    NProgress.start();

    try {
      const { data } = await api.post('v2/person/check_exists', phoneNumber);
      console.log(data);
    } catch (e) {
      const infoErr = e;
      console.warn(e);
      return infoErr;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.POLICY_RELATE_ACTIVATE](ctx, policyId) {
    NProgress.start();

    try {
      await api.put(`v2/policy/activate-relate?policyId=${policyId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Полис активирован!',
      });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        // message: 'Не активировать полис!',
        message: `Не удалось активировать полис ${policyId}, так как на данном полисе есть ГП. Сообщите об этом в договорной отдел`,
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
