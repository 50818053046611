<template>
  <div class="message-wrapper">
    <span
      v-if="isRegularOrTakeOn"
      class="message-wrapper-text"
    >
      {{ createdAtFormatted }}
    </span>
    <span
      v-if="isRegularOrTakeOn"
      class="message-wrapper-text system-message"
    >
      {{ message?.text }}
    </span>
    <span
      v-if="showMessages"
      class="message-wrapper-text system-message"
    >
      {{ getDayAndMonth }}
    </span>
    <div
      v-if="!isRegularOrTakeOn && !isClosedAppeal"
      class="message-container"
      :data-position="messagePosition"
    >
      <div class="text-message">
        <component
          :is="messageComponent"
          :message="message"
          :position="messagePosition"
          :appeal-id="appealId"
        />
      </div>
    </div>
    <span
      v-if="isClosedAppeal"
      class="message-wrapper-text system-message"
    >
      {{ message?.text }} в {{ createdAtFormatted }}
    </span>
  </div>
</template>

<script>
import ChatTextMessage from '@/components/Chat/messages/ChatTextMessage';
import ChatFileMessage from '@/components/Chat/messages/ChatFileMessage';
import { format, parseISO } from '@evd3v/date-fns';
import HubClientService from '@/services/core/hub';

const MESSAGE_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export default {
  name: 'ChatMessageContainer',
  components: {
    ChatTextMessage,
    ChatFileMessage,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    filteredMessages: {
      type: Array,
      required: true,
    },
    appealId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    showDifferentDate() {
      if (this.index > 0) {
        const prevMessage = this.filteredMessages[this.index - 1];
        const actualMessage = this.filteredMessages[this.index];
        return (
          this.formatDate(actualMessage.createdAt) !== this.formatDate(prevMessage.createdAt)
        );
      }
      return true;
    },
    showMessages() {
      return !this.isRegularOrTakeOn && !this.isClosedAppeal && this.showDifferentDate;
    },
    getDayAndMonth() {
      return format(parseISO(this.message.createdAt), 'dd.MM.yy');
    },
    isClosedAppeal() {
      return this.payload?.InfoMessageType === 'Closed';
    },
    payload() {
      return JSON.parse(this.message.payload);
    },
    isRegularOrTakeOn() {
      return this.payload?.InfoMessageType === 'Regular' || this.payload?.InfoMessageType === 'TakeOn';
    },
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm dd.MM.yy');
    },
    messageComponent() {
      return `Chat-${this.message.messageType}-message`;
    },
    messagePosition() {
      if (this.message?.member?.memberType === 'Patient' || this.message?.member?.memberType === 0) {
        return MESSAGE_POSITION.LEFT;
      }
      return MESSAGE_POSITION.RIGHT;
    },
  },
  mounted() {
    try {
      HubClientService.on('chat.wasRead', this.getWasRead);
    } catch (error) {
      console.error(error);
    }
  },
  beforeDestroy() {
    HubClientService.off('chat.wasRead', this.getWasRead);
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy');
    },
    getWasRead(systemMessage) {
      const isCorrectChat = systemMessage.chatId === this.message.chatId;
      const isMemberType = this.messagePosition === MESSAGE_POSITION.RIGHT;

      if (isCorrectChat && isMemberType && !this.message?.wasRead) {
        this.message.wasRead = true;
      }

      return this.message;
    },
    checkMessage(message) {
      this.$emit('input', message);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  &-text{
    display: block;
    text-align: center;
    font-size: 12px;
    color:rgb(191, 193, 194)
  }
  .message-container {
    max-width: 80%;

    &[data-position='left'] {
      align-items: flex-start;
      margin-right: auto;
    }
    &[data-position='right'] {
      align-items: flex-end;
      margin-left: auto;
    }
  }
}
.text-message {
  word-wrap: break-word;
  max-width: 100%;
  position: relative;
  margin-top: 5px;
  padding: 0px 10px 10px 10px;
}
.system-message {
  margin: 5px 0;
  color:rgb(147, 149, 151)
}
</style>
