<template>
  <div class="schedule">
    <loading
      v-if="isLoading"
    >
      Загрузка расписания
    </loading>

    <div v-else-if="!scheduleItems.length && !scheduleLiveItems.length">
      Нет расписания на этот день
    </div>

    <div v-if="isLiveScheduleTemplate && !isLoading">
      <div class="crm-row live-template-value">
        Дежурный врач:
      </div>
      <div class="crm-row">
        <div class="crm-column">
          <div class="live-template-caption">
            Время первого приема
          </div>
          <div class="live-template-value">
            {{ scheduleLiveItems[0].formattedTime }}
          </div>
        </div>
        <div class="crm-column">
          <div class="live-template-caption">
            Время последнего приема
          </div>
          <div class="live-template-value">
            {{ scheduleLiveItems[scheduleLiveItems.length - 1].formattedTime }}
          </div>
        </div>
      </div>
      <div class="crm-row">
        <div class="crm-column">
          <div class="live-template-caption">
            Часовой пояс
          </div>
          <div class="live-template-value">
            {{ parseTimezoneLiveTemplate }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="isScheduleTemplate && !isLoading">
      <div class="scheduleSlotsSection">
        <div
          class="schedule-wrapper schedule-block"
        >
          <custom-scrollbar>
            <base-tabs
              v-model="activeTab"
              :items="scheduleItems"
              class="schedule-table"
            />
          </custom-scrollbar>
        </div>

        <div
          v-if="timeZone"
          class="schedule-block"
        >
          <span class="timezone-title">
            {{ timeZone }}
          </span>
        </div>

        <div
          v-if="request && isLocked"
          class="schedule-block"
        >
          <p class="consultation-title">
            {{ request.consultationTitle }}
          </p>

          <p class="client-title">
            {{ request.userFIO }}
          </p>
          <div class="consultation-button">
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openScheduleCancelModal"
            >
              Отменить консультацию
            </b-button>
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="openScheduleTransferModal"
            >
              Перенести консультацию
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { formatTimeZone } from '@/helpers/utils';
import { BaseTabs } from '@/components/base';
import Loading from '@/components/Loading';

export default {
  name: 'Schedule',
  components: {
    Loading,
    BaseTabs,
  },
  props: {
    doctorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.Schedule.isScheduleLoading;
    },
    isLiveScheduleTemplate() {
      return this.$store.state.Schedule.liveSchedule && this.$store.state.Schedule.liveSchedule.length > 0;
    },
    isScheduleTemplate() {
      return this.$store.state.Schedule.schedule && this.$store.state.Schedule.schedule.length > 0;
    },
    scheduleItems() {
      const { schedule } = this.$store.state.Schedule;

      if (!schedule.length) return [];

      return schedule.map((item) => ({
        ...item,
        title: item.formattedTime,
        value: item.unixTime,
        highlight: item.isLocked,
      }));
    },
    scheduleLiveItems() {
      const { liveSchedule } = this.$store.state.Schedule;

      if (!liveSchedule.length) return [];

      return liveSchedule.map((item) => ({
        ...item,
        title: item.formattedTime,
        value: item.unixTime,
        highlight: !!item.request,
      }));
    },
    request() {
      if (!this.scheduleItems.length) return null;

      const { request = null } = this.scheduleItems.find((item) => item.unixTime === this.activeTab) || {};
      return request;
    },
    timeZone() {
      if (!this.scheduleItems.length) return null;

      const { timeZone = null } = this.scheduleItems.find((item) => item.unixTime === this.activeTab) || {};
      return timeZone ? formatTimeZone(timeZone) : null;
    },
    isLocked() {
      if (!this.scheduleItems.length) return null;

      const { isLocked = null } = this.scheduleItems.find((item) => item.unixTime === this.activeTab) || {};
      return isLocked;
    },
    parseTimezone() {
      return formatTimeZone(this.scheduleItems[0].timeZone);
    },
    parseTimezoneLiveTemplate() {
      return formatTimeZone(this.scheduleLiveItems[0].timeZone);
    },
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.activeTab = null;
      }
    },
    activeTab(newVal) {
      if (newVal && !this.isLocked) {
        this.$emit('change-active-tab', newVal);
        return;
      }
      this.$emit('change-active-tab', null);
    },
  },
  methods: {
    openScheduleCancelModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleCancelModal',
        props: {
          consultation: {
            ...this.request,
            id: this.request.consultationId,
          },
          doctorId: this.doctorId,
        },
      });
    },
    openScheduleTransferModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleTransferModal',
        props: {
          consultationProp: {
            ...this.request,
            id: this.request.consultationId,
          },
          doctorId: this.doctorId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-wrapper {
  height: 190px;
  min-height: 100px;
}
.schedule-block {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(218, 218, 218, 0.7);

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.consultation-button{
  margin-top: 30px;
}
::v-deep.schedule-table {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-row-gap: 10px;
  justify-content: space-evenly;

  .crm-tab {
    background: #F2F4F5;
    border-radius: 5px;
    width: 65px;
    height: 30px;
    padding: 0;
    border: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    color: $black;

    &.highlight {
      background: #38E3C3;
      color: $white;
    }

    &.active {
      color: $white;
      background-color: $blue-dark;
    }
  }
}
.consultation-title {
  margin: 0 0 20px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}
.timezone-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}
.client-title {
  margin: 0;
  color: #908F8D;
}

.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

.live-template-caption {
  font-size: 16px;
  color: $black-light;
}

.live-template-value {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  color: $black-light;
}
.scheduleSlotsSection {
  margin-top: 30px;
}
</style>
