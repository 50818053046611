<template>
  <b-modal
    v-model="isOpen"
    centered
    size="md"
    title="Проверка данных"
    hide-footer
    @hidden="onClose"
  >
    <template v-if="forAndrey">
      <div class="font-weight-bold text-center mb-2 h6">
        Проверки только на номер полиса, ФИО, период действия полиса и дубли в документе
      </div>
    </template>

    <template v-if="isCheckingState">
      <div class="crm-info-text">
        Ведется проверка, не закрывайте окно, <br>
        может занять несколько минут
      </div>
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else-if="isImportLoading">
      <div class="crm-info-text">
        Данные сохраняются, не закрывайте окно, <br>
        может занять несколько минут
      </div>
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else-if="isSuccessState">
      <div class="crm-info-text success">
        Расхождения не найдены. <br> Возможно выполнение записи в базу данных.
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="button button-import"
          @click="startImport"
        >
          <span>Записать данные</span>
        </b-button>
      </div>
    </template>

    <template v-else-if="isErrorState">
      <div class="crm-info-text error">
        Расхождения найдены. <br> Таблицу с типами ошибок можно скачать.
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="crm-button crm-button-import"
          @click="startImport"
        >
          <span>Записать успешные</span>
        </b-button>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="crm-button crm-button-file"
          @click="downloadTable"
        >
          <span>Скачать таблицу</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';

export default {
  name: 'LegalEntityReestrImportModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    body: {
      type: Object,
      default: null,
    },
    forAndrey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
      isCheckingState: false,
      isSuccessState: false,
      isErrorState: false,
      isImportLoading: false,
      successfullyChecked: [],
    };
  },
  async created() {
    try {
      this.isCheckingState = true;
      const { data: { fileWithErrorsId, successfullyChecked } } = await api.post(
        'v2/registry/check',
        this.body,
      );
      console.log(successfullyChecked);
      this.isCheckingState = false;
      this.successfullyChecked = successfullyChecked.map((item) => ({
        ...item,
        insuranceCompanyIds: item.insuranceCompanyIds.split(';').map((companyId) => Number(companyId)),
        insuranceSubprogramIds: item.insuranceSubprogramIds.split(';').map((subprogramId) => Number(subprogramId)),
      }));

      if (fileWithErrorsId) {
        this.isErrorState = true;
        this.fileGUIDForDownload = fileWithErrorsId;
      } else {
        this.isSuccessState = true;
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async downloadTable() {
      const { data: fileName } = await api.get(`File/name/${this.fileGUIDForDownload}`);
      const { data: file } = await api.get(`File/${this.fileGUIDForDownload}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
    async startImport() {
      this.isImportLoading = true;
      try {
        const body = {
          legalPersonId: this.body.legalPerson.id,
          services: this.successfullyChecked,
          actNumber: this.body.actNumber,
          month: this.body.month,
          year: this.body.year,
        };

        await api.post('v2/registry/save', body);
        this.onClose();
      } catch (e) {
        console.log(e);
      } finally {
        this.isImportLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-info-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;

    &.success {
      color: #219653;
    }

    &.error {
      color: #EB5757;
    }
  }
</style>
