<template>
  <div class="consultation-detail">
    <custom-scrollbar>
      <div class="crm-inner">
        <div class="crm-title d-flex w-100">
          Информация о талоне

          <b-button
            v-if="consultation.status === 0"
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="text-nowrap ml-auto"
            @click="openConsultationControlModal"
          >
            Отменить талон
          </b-button>
        </div>
        <div class="crm-subtitle">
          ФИО пациента
        </div>
        <div class="full-name">
          {{ consultation.clientInfo?.fullName }}
        </div>
        <div class="crm-subtitle">
          Текст консультации
        </div>
        <div class="text-body">
          {{ consultation.consultation?.body }}
        </div>

        <div class="crm-line" />
        <div class="crm-row crm-mt crm-align-center">
          <div class="crm-column">
            <div
              class="crm-status"
              :class="statusColor"
            >
              {{ statusText }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Время создания
            </div>
            <div class="value crm-bold">
              {{ createDate }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Название очереди
            </div>
            <div class="value crm-bold">
              {{ consultation.queueName }}
            </div>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { parseISO, format } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';

import { QUEUE_CONSULTATION_STATUSES } from '@/helpers/consts';

export default {
  name: 'PatientDetailConsultation',
  mixins: [mixinRoles],
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      consultationStatuses: QUEUE_CONSULTATION_STATUSES,
      statusColors: {
        0: 'green',
        1: 'red',
        2: 'red',
      },
    };
  },
  computed: {
    createDate() {
      return this.consultation.createdAt ? `${format(parseISO(this.consultation.createdAt), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    statusText() {
      return this.consultationStatuses[this.consultation.status];
    },
    statusColor() {
      return this.statusColors[this.consultation.status];
    },
  },
  methods: {
    openTransferModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationTransferModal',
        props: {
          consultationProp: this.consultation,
        },
      });
    },
    openCancelModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationCancelModal',
        props: {
          consultation: this.consultation,
        },
      });
    },
    openConsultationControlModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'QueueConsultationDeleteModal',
        props: {
          consultationId: this.consultation.id,
          afterDelete: () => {
            Bus.$emit('consultations:refresh');
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-detail {
  box-sizing: border-box;
  height: 100%;
  flex: 1;
}

.crm-inner {
  height: 100%;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
}
.crm-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}
.crm-subtitle {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #707070;
}

.full-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
}

.text-body {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 20px;
}

.crm-line {
  height: 1px;
  background-color: #E0E0E0;
  width: 100%;
}

.crm-row {
  display: flex;

  & + & {
    margin-top: 20px;
  }

  &.crm-align-center {
    align-items: center;
  }

  &.crm-mt {
    margin-top: 30px;
  }
}

.crm-column {
  flex: 1;

  & + & {
    margin-left: 20px;
  }

}

.crm-status {
  text-align: center;
  border-radius: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  padding: 0 10px;

  &.green {
    background: #e6ffd1;
    color: $green;
  }
  &.yellow {
    background: #FFE6D1;
    color: #F2994A;
  }
  &.red {
    background: #ffdede;
    color: $red;
  }
}

.crm-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 20px;
}

.crm-button {
  width: unset;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: normal;
}

.specialization {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #4B62C1;
}

.doctor {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.crm-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &.crm-bold {
    font-weight: 600;
  }

  &.crm-nowrap {
    white-space: nowrap;
  }
}

::v-deep.base-dropdown-wrapper {
  margin-top: 30px;

  .crm-dropdown-row {
    height: 1px!important;
  }
}

.protocol-info {
  margin-top: 20px;
}
</style>
