import ConsultationApi from '@/services/consultation/consultation.api';

export default class ConsultationService {
  constructor($axios) {
    this.api = new ConsultationApi($axios);
  }

  getProtocol(id) {
    return this.api.getProtocol(id);
  }
}
